/* :root {
  --bg-navbar: #000000;
  --bg-primary: #D50000;
  --bg-secondary: #404040;
  --bt-confirm: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
  --text-bt-confirm: #000000;
  --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
  --text-bt-login: #FFFFFF;
  --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
  --bg-label-money: #262626;
  --bg-camp: #000000;
  --text-secondary: #989898;
  --bg-card: linear-gradient(180deg, #434343 0%, #2F2F2F 12.5%, #1C1C1C 89.06%);
  --bg-select: linear-gradient(180deg, #D40000 0%, #FF6060 9.11%, #D40000 45.05%, #D40000 73.18%, #D40000 100%);
  --bg-floor: #FFFFFF;
  --bg-sidebarMenu-floor: #000000;
  --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
  --bt-sucess: #50B700;
  --bt-danger: #EA0000;
  --bg-warning: #FFE000;
  --bg-blue: #0034BB;
  --bg-black: #000000;
  --bg-white: #FFFFFF;
  --bg-text-main: #FFFFFF;
  --bg-play-game: #202020;
  --bg-popup: #272829;
  --bg-floor-popup: rgba(106, 0, 0, 0.6);
} */

/* Position */
.positionAbsolute {
  position: absolute;
}

.positionFixed {
  position: fixed;
}

.positionRelative {
  position: relative;
}

.topLeft {
  top: 0;
  left: 0;
}

.topRight {
  top: 0;
  right: 0;
}

.topCenter {
  top: 50%;
}

.bottomLeft {
  bottom: 0;
  left: 0;
}

.bottomRight {
  bottom: 0;
  right: 0;
}

.bottomCenter {
  bottom: 0;
  left: 0;
}

.leftCenter {
  left: 50%;
}

.rightCenter {
  right: 50%;
}

.traslateXCenter {
  transform: translateX(-50%);
}

.traslateYCenter {
  transform: translateY(-50%);
}

.traslateCenter {
  transform: translate(-50%, -50%);
}

/* Width */
.widthFull {
  width: 100%;
}

/* Z-Index */
.zIndexLow {
  z-index: 1000;
}

.zIndexMedium {
  z-index: 1200;
}

.zIndexHight {
  z-index: 1400;
}

.zIndexLarge {
  z-index: 1600;
}

.zIndexMax {
  z-index: 9999;
}

.zIndexBG {
  z-index: -1;
}

/* Text Bold */
.textBoldLow {
  font-weight: 400;
}

.textBoldMedium {
  font-weight: 500;
}

.textBoldHight {
  font-weight: 600;
}

.textBold {
  font-weight: bold;
}

/* Cursor */
.cursorPointer {
  cursor: pointer;
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

/* Margin */
.componentMarginTop {
  margin-top: 50px;
}

.boxMarginTop {
  margin-top: 30px;
}

.contentMediumMarginTop {
  margin-top: 20px;
}

.contentMarginTop {
  margin-top: 10px;
}

/* Container */
.contentWidth {
  padding: 0px 12px;
}

/* Game List */
.gameImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.gameImage:hover {
  top: 2px;
}

.gameNameBox {
  padding: 6px 4px;
  background: var(--label-name);
  /* background: rgba(0, 0, 0, 0.7); */
  border-radius: 0px 0px 10px 10px;
}

.gameNameBottom {
  bottom: 4px;
  left: 0;
}

.gameName {
  font-size: 18px;
  color: var(--bg-white);
}

.gameNameSzie {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/* boxContent */
.formPadding {
  /* padding: "0px 20px 0px 20px"; */
}

.boxTitle {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--bg-text-main);
}

.boxContentTitle {
  font-size: 20px;
  color: var(--bg-text-main);
}

/* Login */
.validateError {
  padding-top: 6px;
  font-size: 14px;
  color: #ff6877;
  padding-left: 8px;
}

.validateInfo {
  color: var(--bg-white);
}

/* Display */
.displayInlineBlock {
  display: inline-block;
}

.displayFlex {
  display: flex;
}

/* Flex */
.flexCenter {
  justify-content: center;
}

/* Icon Close Size */
.iconCloseAll {
  position: relative;
  color: white;
  width: 46px !important;
  height: 100% !important;
  cursor: pointer;
}

.iconCloseAll:hover {
  top: 2px;
}

.iconBackPage,
.iconHomePage {
  color: var(--bg-text-main);
  width: 42px !important;
  height: 100% !important;
}

.iconSearchPage {
  color: var(--text-bt-confirm);
  width: 32px !important;
  height: 100% !important;
  background: var(--bt-confirm);
  padding: 4px;
  border-radius: 100%;
}

.iconBackPage:hover,
.iconHomePage:hover,
.iconSearchPage:hover {
  top: 2px;
}

.pagePadding {
  padding-top: 40px;
}

.slick-prev, .slick-next {
  z-index: 999 !important;
}

.slick-next {
  right: 25px !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-prev:before, .slick-next:before {
  font-size: 36px !important;
}

/* Scrol */
.menuPositionWidthScroll {
  width: auto;
  /* white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden; */
  margin-left: 8px;
  margin-right: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
}

.menuPositionWidthScroll::-webkit-scrollbar {
  height: 14px !important;
}

.menuPositionWidthScroll::-webkit-scrollbar-thumb {
  background: var(--bg-white);
  border-radius: 20px;
}

.boxDetailQRCodeGenTop {
  padding: 40px 0px 0px 0px;
  background: var(--bg-white);
}

.boxDetailQRCodeScanNote {
  color: var(--bt-danger);
  padding: 20px 0px;
  font-size: 14px;
}

.popupLineTitle {
  color: var(--bt-sucess);
  font-weight: 600;
  font-size: 20px;
}

.popupLineDetail {
  color: var(--bg-white);
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
}

.popupLineQRCode {
  margin-top: 40px;
  position: relative;
}

.popupLineQRCodeBG {
  display: inline-block;
  background-color: var(--bg-white);
  padding: 10px;
  border-radius: 12px;
}

.popupLineIconPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popupLineIcon {
  width: 40px;
  height: auto;
  background-color: var(--bg-white);
  border-radius: 8px;
  padding: 2px 4px;
}

.popupLineIconBtn {
  width: 32px;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .componentMarginTop {
    margin-top: 30px;
  }

  .boxMarginTop {
    margin-top: 20px;
  }

  .contentWidth {
    padding: 0px 8px !important;
  }

  .boxTitle {
    font-size: 18px;
  }

  .boxContentTitle {
    font-size: 15px;
  }

  .contentMediumMarginTop {
    margin-top: 10px;
  }

  .iconBackPage {
    width: 30px !important;
  }

  .iconHomePage {
    width: 26px !important;
  }

  .iconSearchPage {
    width: 24px !important;
    padding: 3px;
  }

  .gameNameBox {
    padding: 3px 4px;
  }

  .gameName {
    font-size: 13px;
  }

  .pagePadding {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 30px !important;
  }
  
  .slick-next {
    right: 20px !important;
  }

  .popupLineIconBtn {
    width: 25px;
  }
  
}