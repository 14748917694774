/* :root {
    --bg-navbar: #000000;
    --bg-primary: #D50000;
    --bg-secondary: #404040;
    --bt-confirm: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --text-bt-confirm: #000000;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #262626;
    --bg-camp: #000000;
    --text-secondary: #989898;
    --bg-card: linear-gradient(180deg, #434343 0%, #2F2F2F 12.5%, #1C1C1C 89.06%);
    --bg-select: linear-gradient(180deg, #D40000 0%, #FF6060 9.11%, #D40000 45.05%, #D40000 73.18%, #D40000 100%);
    --bg-floor: #FFFFFF;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #50B700;
    --bt-danger: #EA0000;
    --bg-warning: #FFE000;
    --bg-blue: #0034BB;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-text-main: #FFFFFF;
    --bg-play-game: #202020;
    --bg-popup: #272829;
} */

.contactTitle {
    font-size: 24px;
    color: var(--bg-text-main);
}

.contactTitleSub {
    font-size: 18px;
    color: var(--text-secondary);
}

.contactMenuList {
    background: var(--bg-card);
    border-radius: 10px;
    color: var(--bg-text-main);
    padding: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid var(--bg-secondary);
    margin-bottom: 15px;
}

.contactMenuList:hover {
    top: 2px;
}

.contactMenuListText {
    font-size: 18px;
}

.contactMenuListImg {
    width: 100%;
    height: auto;
    max-width: 64px;
}

/* Responsive */
@media only screen and (max-width: 600px) {
    .contactMenuListImg {
        max-width: 42px;
    }
    .contactMenuListText {
        font-size: 16px;
    }
    .contactMenuList {
        padding: 10px 10px 6px 10px;
    }
    .contactTitle {
        font-size: 18px;
    }
    .contactTitleSub {
        font-size: 16px;
    }
}
