/* :root {
    --bg-navbar: #000000;
    --bg-primary: #D50000;
    --bg-secondary: #404040;
    --bt-confirm: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --text-bt-confirm: #000000;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #262626;
    --bg-camp: #000000;
    --text-secondary: #989898;
    --bg-card: linear-gradient(180deg, #434343 0%, #2F2F2F 12.5%, #1C1C1C 89.06%);
    --bg-select: linear-gradient(180deg, #D40000 0%, #FF6060 9.11%, #D40000 45.05%, #D40000 73.18%, #D40000 100%);
    --bg-floor: #FFFFFF;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #50B700;
    --bt-danger: #EA0000;
    --bg-warning: #FFE000;
    --bg-blue: #0034BB;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-text-main: #FFFFFF;
    --bg-play-game: #202020;
    --bg-popup: #272829;
} */

.formControl {
    display: "flex";
    flex-wrap: "wrap";
}

.formPadding {
    padding: 0px 20px 0px 20px;
}

.inputStyles {
    background: var(--bg-white);
    border-radius: 10px;
    padding: 6px 0px;
    font-size: 18px;
    line-height: 21px;
    color: var(--bg-black);
    height: 100%;
    width: 96%;
    outline: none;
    border: 1px solid var(--bg-black);
}

.inputStylesBorderNone {
    border: none;
}

.selectStyles {
    background: var(--bg-white);
    border-radius: 10px;
    padding: 8px 4px;
    font-size: 18px;
    line-height: 21px;
    color: var(--bg-black);
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
}

.inputTextLabel {
    color: var(--bg-white);
    padding-bottom: 12px;
    padding-left: 8px;
    font-size: 18px;
}

.inputPaddingTop {
    margin-bottom: 20px;
}

.inputPaddingRegisterTop {
    margin-bottom: 10px;
}

.errorText {
    color: #f34444;
    font-size: 14px;
}

.error {
    color: #f34444;
    font-size: 14px;
    border: solid 2px #f34444;
}

.rememberPassword {
    color: #ffd048;
    cursor: pointer;
    padding-right: 10px;
}

.btnPaddingTop {
    padding-top: "16px";
}

/* .borderSocial {
    border-top: solid 1px var(--bg-white);
    position: relative;
    margin: 50px 0px 50px 0px;
    width: 100%;
}

.orPositionCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.orText {
    border: 1px solid var(--bg-white);
    border-radius: 60px;
    font-size: 16px;
    color: var(--bg-black);
    padding: 12px 10px;
    background: var(--bg-white);
} */

.inputTM {
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 10px;
    background: transparent;
    height: 50px;
    padding: 0 10px 0 10px;
    color: var(--bg-white);
}

.inputSelectTM {
    border: 1px solid var(--bg-white);
    box-sizing: border-box;
    border-radius: 10px;
    height: 50px;
    color: var(--bg-black);
    padding: 0 10px 0 10px;
    background: var(--bg-white);
}

.inputCheckActive {
    width: 24px;
    height: 24px;
    background: #0013be;
    border: 1px solid #898989;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 3px 0 0 3px;
}

.inputCheck {
    width: 32px;
    height: 32px;
    background: #c4c4c4;
    border: 1px solid #898989;
    box-sizing: border-box;
    border-radius: 20px;
}

.inputCheckText {
    font-size: 16px;
    color: var(--bg-white);
}

.inputNotRegister{
    font-size: 18px;
    color: var(--bg-white);
}

.iconSize {
    width: 34px !important;
    height: 34px !important;
    color: var(--bg-black);
    margin-top: 4px;
}

.iconSizeSub {
    width: 38px;
    height: 38px;
}

.imgBank {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.imgBankNotSelect {
    position: relative;
    filter: grayscale(1);
    cursor: pointer;
}

.imgBankNotSelect:hover {
    top: 2px;
}

.inputAdornment {
    font-size: 16px;
}

.inputStylesRadius {
    border-radius: 40px;
}

@media only screen and (max-width: 600px) {
    .iconSizeSub {
        width: 28px;
        height: 28px;
    }

    .inputStyles {
        padding: 4px 0px;
    }

    .inputTextLabel {
        font-size: 15px;
    }

    .iconSize {
        width: 28px !important;
        height: 28px !important;
    }

    .borderSocial {
        margin: 40px 0px 40px 0px;
    }

    .selectStyles {
        padding: 5px 4px;
    }

    .inputNotRegister {
        font-size: 16px;
    }
}