.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.relative {
  position: relative;
}

.progressiveImage {
  width: 100%;
}

.playGameHtmlIframe {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
}
