/* :root {
    --bg-navbar: #000000;
    --bg-primary: #D50000;
    --bg-secondary: #404040;
    --bt-confirm: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --text-bt-confirm: #000000;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #262626;
    --bg-camp: #000000;
    --text-secondary: #989898;
    --bg-card: linear-gradient(180deg, #434343 0%, #2F2F2F 12.5%, #1C1C1C 89.06%);
    --bg-select: linear-gradient(180deg, #D40000 0%, #FF6060 9.11%, #D40000 45.05%, #D40000 73.18%, #D40000 100%);
    --bg-floor: #FFFFFF;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #50B700;
    --bt-danger: #EA0000;
    --bg-warning: #FFE000;
    --bg-blue: #0034BB;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-text-main: #FFFFFF;
    --bg-play-game: #202020;
    --bg-popup: #272829;
} */

/* BTN Action */
.btnHover {
    position: relative;
}

.btnHover:hover {
    top: 2px;
}

.btnRadius {
    border-radius: 50px;
}

.btnRadiusMedium {
    border-radius: 10px;
}

.btnRadiusLow {
    border-radius: 5px;
}

.btnWidthLow {
    width: 140px;
}

.btnWidthMedium {
    width: 200px;
}

.btnWidthHight {
    width: 260px;
}

.btnWidthLarge {
    width: 320px;
}

.btnWidthFull {
    width: 100%;
}

.btnActionPosition {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-60%);
}

/* BTN Style */
.btn {
    display: inline-block;
    /* font-weight: 400; */
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 0px;
    font-size: 18px;
    line-height: 1.5;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 0px;
    color: var(--bg-white);
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btnFilter {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.btnLogin {
    color: var(--text-bt-login);
    background: var(--bt-login);
}

.btnConfirm {
    color: var(--text-bt-confirm);
    background: var(--bt-confirm);
}

.btnSuccess {
    color: var(--bg-white);
    background: var(--bt-sucess);
}

.btnDanger {
    color: var(--bg-white);
    background: var(--bt-danger);
}

.btnBlue {
    color: var(--bg-white);
    background: var(--bg-blue);
}

.btnOutLineWhite {
    border: 2px solid var(--bg-text-main) !important;
    background: unset;
    color: var(--bg-text-main);
}

.btnNavbar {
    background: var(--bg-navbar);
    color: var(--bg-white);
}

.btnReqBank {
    font-size: 16px;
    color: var(--bg-black);
    background: var(--bt-confirm);
}

.btnSelectPromotion {
    font-size: 13px;
    padding: 5px;
    color: var(--bg-white);
    background: var(--bg-blue);
}

.btnCancelPromotion {
    font-size: 13px;
    padding: 5px;
    color: var(--bg-white);
    background: var(--bt-danger);
}

.btnRule {
    font-size: 13px;
    padding: 5px;
    color: var(--bg-black);
    background: var(--bt-confirm);
}

.btnLogin:hover,
.btnConfirm:hover,
.btnSuccess:hover,
.btnDanger:hover,
.btnReqBank:hover {
    opacity: .8;
}

.btnFooter {
    text-align: center;
    padding: 12px 24px;
    border-radius: 40px;
    font-size: 18px;
    cursor: pointer;
    color: var(--bg-text-main);
    border: 2px solid var(--bg-text-main);
    background: transparent;
    position: relative;
}

.btnFooter:hover {
    top: 2px;
    background: var(--bg-white);
    color: var(--bg-black);
}

.backButton {
    color: var(--bg-text-main);
    cursor: pointer;
    width: 42px !important;
    height: 100% !important;
}

.btnSpin {
    padding: 6px 0px;
}

.btnSpinStart {
    background: linear-gradient(180deg, #D90000 0%, #FF7C7C 5.73%, #DA0000 57.81%, #930000 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
    font-size: 26px;
}

.btnSpinWait {
    background: linear-gradient(180deg, #D9D9D9 0%, #FFFFFF 5.73%, #DADADA 57.81%, #939393 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
    font-size: 26px;
    color: #4F4F4F;
}

.btnSpinHistory {
    background: linear-gradient(180deg, #2587E0 0%, #0400CD 28.13%, #0A0045 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
}

.btnSpinReward {
    background: linear-gradient(180deg, #AD00FF 0%, #8F00FF 29.69%, #1B003E 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
}

.btnPWAInstall {
    background: #0078d1;
    font-size: 16px;
    padding: 8px 0px;
}

.btnPWACancel {
    background: unset;
    color: var(--bt-danger);
    font-size: 16px;
    padding: 8px 0px;
}

/* Responsive */
@media only screen and (max-width: 600px) {
    .btn {
        font-size: 16px;
        padding: 10px 0px;
    }

    .btnRes {
        font-size: 13px;
        padding: 4px 0px;
        width: 90px !important;
    }

    .btnOutLineWhite {
        border: 1px solid var(--bg-white);
    }

    .btnWidthMedium {
        width: 160px;
    }

    .btnWidthHight {
        width: 200px;
    }

    .btnFooter {
        font-size: 16px;
        border: 1px solid var(--bg-text-main);
    }

    .btnSpinStart, 
    .btnSpinWait {
        font-size: 18px;
    }

    .btnSpin {
        padding: 5px 0px;
        font-size: 14px;
    }
}